import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";

import theme from "./theme";
import Logo from "./component/Logo";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0 20px 40px 20px",
  },
  logo: {
    paddingTop: 60,
    height: 200,
  },
  title: {
    fontWeight: 300,
    fontSize: 32,
    textAlign: "center",
  },
  p: {
    fontWeight: 300,
    fontSize: 20,
    margin: "20px 0",
  },
});

function App() {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <div className={classes.root}>
                <Logo className={classes.logo} />
                <h1 className={classes.title}>AA Syd Online</h1>
                <p className={classes.p}>
                  AA Syd Online var en AA-grupp som bildades 2020 för att nyktra
                  alkoholister skulle kunna gå på AA-möten även under pandemin,
                  då det inte längre var möjligt att gå på fysiska möten. Det
                  har sedan dess åter blivit möjligt att gå på fysiska AA-möten.
                  AA Syd Online har därför valt att upphöra med sin verksamhet.
                </p>
                <p className={classes.p}>
                  Det finns över 500 AA-grupper utspridda över Sverige som
                  regelbundet håller fysiska möten. Det finns även grupper som
                  håller möten online. Hitta ditt nästa möte på{" "}
                  <Link href="https://www.aa.se/hitta-ett-mote/">aa.se</Link>.
                </p>
                <p className={classes.p}>
                  Tack för den här tiden och ta hand om er ❤️
                </p>
              </div>
            )}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
