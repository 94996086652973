import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import grey from "@material-ui/core/colors/grey";

const theme = createMuiTheme({
  palette: {
    background: {
      default: grey[200],
    },
    secondary: {
      main: grey[800],
    },
  },
});

export default theme;
