import React from "react";

function Logo({ className }) {
  return (
    <svg className={className} viewBox="0 0 405 405">
      <g transform="matrix(1.25 0 0 -1.25 .32325 862.78)">
        <path
          transform="matrix(1.8983 0 0 -1.8983 442.79 694.44)"
          d="m-62.711 87.564a85.339 85.339 0 1 1-170.68 0 85.339 85.339 0 1 1 170.68 0z"
          fill="#fff"
        />
        <g transform="matrix(.1 0 0 .1 -.2586 -.2586)" fill="#231f20">
          <path d="m2034 5140v524.2c-0.22 22.64 0.66 56.03-13.12 87.79-6.94 15.8-18.42 31.35-35.51 42.2-17.06 10.91-38.75 16.65-64.26 16.61-25.62 0.04-47.4-5.85-64.46-16.99-25.82-16.8-37.83-43.07-43.16-66.61-5.44-23.79-5.23-46.77-5.28-63v-549.22h225.79v25.02" />
          <path d="m2300.2 5676.7-651.11 1127.8c408.32-7.67 776.87-176.04 1045.7-444.83 275.16-275.22 445.18-654.9 445.2-1074.8 0-266.7-68.7-517.12-189.24-734.92l-650.57 1126.8zm-491.94-646.35v-425.8h-94v425.8h-57.34v84.67l57.34-0.02v549.22c0.23 94.78 30.26 152.9 70.12 189.35 39.95 36.38 92.36 51.26 138.66 51.26 32.62 0.19 111.8-7.71 157.57-68.44 47.88-67.76 47.14-115.7 47.46-172.17v-1059.7h-94.02v425.82h-225.79zm886.55-820.32c-275.22-275.14-654.9-445.16-1074.8-445.18-419.89 0.02-799.57 170.04-1074.8 445.18-86.492 86.52-162.58 183.38-226.28 288.55h2602.2c-63.7-105.17-139.79-202.03-226.29-288.55zm-1491.6 820.32v-425.8h-94v425.8h-57.35v84.67l57.35-0.02v549.22c0.25 94.78 30.28 152.9 70.12 189.35 39.95 36.38 92.35 51.26 138.66 51.26 32.64 0.19 111.8-7.71 157.57-68.44 47.91-67.77 47.13-115.7 47.48-172.17v-1059.7h-94.02v425.82h-225.81zm-1103.2 254.49c0.019 419.89 170.04 799.57 445.18 1074.8 268.87 268.79 637.42 437.16 1045.8 444.83l-651.12-1127.8-650.55-1126.8c-120.56 217.8-189.24 468.23-189.26 734.94zm1520 1620c-894.72-0.02-1620-725.27-1620-1620 0.042969-894.74 725.29-1620 1620-1620 894.71 0.04 1620 725.27 1620 1620-0.03 894.72-725.29 1620-1620 1620" />
          <path d="m1429 5140v524.2c-0.21 22.64 0.63 56.03-13.14 87.79-6.94 15.8-18.4 31.35-35.49 42.2-17.08 10.91-38.75 16.65-64.27 16.61-25.61 0.04-47.4-5.85-64.45-16.99-25.83-16.8-37.85-43.07-43.18-66.61-5.44-23.79-5.24-46.77-5.28-63v-549.22h225.81v25.02" />
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Logo);
